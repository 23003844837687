import { v4 as uuid } from 'uuid'

import {
  ANSWER_VALUE_MATCH,
  FIND_WITH_INDEX_NOT_FOUND,
  ANSWERING_IDS_MATCH,
  Answer,
  Answers,
  Questions,
  QuestionLayout,
  Options,
  OptionGroupSelectUnionType,
  ValuesOf,
  SegmentsLocation,
  TextLocation,
  Writable,
  Option,
  extractPropertiesFromCustomText,
  OptionValueProperties,
  AnswerRelevance,
  isModeDocumentFlow,
  LanguageValue,
  VALUE_SOURCE_TYPE_MATCH,
  ValueSourceProperties,
  MarkerReplace,
  LOGIC,
  QuestionConditionRule,
  QuestionConditionGroup,
  QuestionLayoutRegularGroup,
  COMPUTED_VALUES,
  SOURCE_VALUE_MATCH,
} from '___types'
import { WizardState, extractFromNestedStructure, findWithIndex, fullAssign } from '.'
import { updateWizardState } from './general'
import { getQuestionById, FoundMarkerType, getMarkerById, getQuestionLayoutGroupByQuestionId } from './template-automation'
import { applyParagraphNumbering } from './editor-content'
import { selectWizardDependentsById } from '../selectorFunctions'
// import { generateLooseQuestionLayoutGroup, mergeQuestionLayoutGroups } from '../../helpers/template-creation'

// ====================================================================================================================================== //
// ============================================================= GENERATORS ============================================================= //
// ====================================================================================================================================== //
const generateRegularGroupIdOrderNumber = (id: string, orderNumber: number, index: number): string =>
  `${id}:${orderNumber.toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })}-${(index + 10).toString(36)}`
const generateLooseGroupIdOrderNumber = (id: string, orderNumber: number): string =>
  `${id}:${orderNumber.toLocaleString(undefined, { minimumIntegerDigits: 2, useGrouping: false })}`
const genericAnswer = { id: '', values: [] } as Answer
const generateAnswer = (answer: Answer): Answer => Object.assign({}, genericAnswer, { id: uuid(), values: [] }, answer)
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ==================================================== GET UNANSWERED QUESTION INFO ==================================================== //
// ====================================================================================================================================== //
export const getUnansweredQuestionInfo = (answers: Answers, questionOrder: string[]): [string | undefined, number] => {
  const answeredQuestionIds = (answers || []).reduce((result, { id, values }) => (values.length ? result.concat(id) : result), [] as string[])
  return (questionOrder || []).reduce(
    ([result, count], questionOrderId) => {
      const { questionId } = questionOrderId.match(ANSWERING_IDS_MATCH)?.groups || {}
      const answered = answeredQuestionIds.includes(questionId as string) || questionId === 'new-instance-prompt'
      if (answered) return [result, count]
      return [result || questionOrderId, count + 1] as [string, number]
    },
    [undefined, 0] as [string | undefined, number]
  )
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ================================================= QUESTIONNAIRE NAVIGATION CONSTANTS ================================================= //
// ====================================================================================================================================== //
// export const QUESTIONNAIRE_PRE_STEPS = { PRE_STEP: 'pre-answering' } as const
// export const QUESTIONNAIRE_POST_STEPS = {
//   SKIPPED: 'skipped-question-review',
//   CONFIRM: 'confirmation',
//   RENAME: 'document-rename',
// } as const
const QUESTIONNAIRE_NAVIGATION_DIRECTIONS = { FORWARDS: 'forwards', BACKWARDS: 'backwards' } as const
type QuestionnaireNavigationDirection = ValuesOf<typeof QUESTIONNAIRE_NAVIGATION_DIRECTIONS>
type QuestionnaireNavigationConditionalMethodsType = {
  [key: string]: {
    conditional: (state: WizardState, direction: QuestionnaireNavigationDirection, previous: string) => boolean
    method: (state: WizardState, direction: QuestionnaireNavigationDirection) => WizardState
  }
}
const QUESTIONNAIRE_NAVIGATION_CONDITIONAL_METHODS = {
  // skipQuestionsSkippedSection: {
  //   conditional: (state: WizardState): boolean =>
  //     state.answering === QUESTIONNAIRE_POST_STEPS.SKIPPED &&
  //     Boolean(state.answers?.length && state.questionOrder?.length) &&
  //     getUnansweredQuestionInfo(state.answers!, state.questionOrder!)[1] === 0,
  //   method: (state: WizardState, direction: QuestionnaireNavigationDirection): WizardState => {
  //     if (direction === QUESTIONNAIRE_NAVIGATION_DIRECTIONS.FORWARDS) return updateWizardState(state, { answering: QUESTIONNAIRE_POST_STEPS.CONFIRM })
  //     const lastQuestionInOrder = state.questionOrder![state.questionOrder!.length - 1]
  //     return updateWizardState(state, { answering: lastQuestionInOrder })
  //   },
  // },
} as QuestionnaireNavigationConditionalMethodsType
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ============================================================= GET ANSWER ============================================================= //
// ====================================================================================================================================== //
const getAnswer = (state: WizardState, test: (answer: Answer) => boolean): [Answer, number] | Writable<typeof FIND_WITH_INDEX_NOT_FOUND> =>
  findWithIndex(state.answers || [], test)
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================== GET ANSWER BY ID ========================================================== //
// ====================================================================================================================================== //
const getAnswerById = (state: WizardState, id: string): [Answer, number] | Writable<typeof FIND_WITH_INDEX_NOT_FOUND> =>
  id ? getAnswer(state, answer => answer.id === id) : (FIND_WITH_INDEX_NOT_FOUND.slice() as Writable<typeof FIND_WITH_INDEX_NOT_FOUND>)
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================== GET ANSWER VALUE ========================================================== //
// ====================================================================================================================================== //
const getAnswerValue = (answer: Answer, test: (value: string) => boolean): [string, number] | Writable<typeof FIND_WITH_INDEX_NOT_FOUND> =>
  findWithIndex(answer.values || [], test)
// ====================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ============================================================ TIDY ANSWER ============================================================ //
// ===================================================================================================================================== //
type TemporaryOptionGroupType = { id: string; options: Options; select: OptionGroupSelectUnionType; maximum: number; enforceLimit: boolean }
const tidyAnswer = (state: WizardState, answer: Answer): Answer => {
  const { optionGroups = [] } = getQuestionById(state, answer.id)[0] || {}
  // remove type conversion after the below is fixed/implemented
  const optionGroupsWithLimits = (optionGroups as unknown as TemporaryOptionGroupType[]).map(
    ({ id, options, select, maximum, enforceLimit = true }) => {
      // remove the line above and uncomment the next one after fixing the optionGroup select (string => Object({ select: string, minimum: number, maximum: number, enforceLimit: boolean }))
      // const optionGroupsWithLimits = optionGroups.map(({ options, select: { select, maximum, enforceLimit } }) => ({
      const selectMaximum = (select as never as OptionGroupSelectUnionType) === 'single' ? 1 : maximum // remove type conversion after the above is fixed/implemented
      return { id, optionIds: options.map(option => option.id), count: (enforceLimit && selectMaximum) || Infinity }
    }
  )

  //@ts-ignore
  const reversedValues = answer!.values.slice().toReversed() as string[]
  const instancedValues = reversedValues.reduce((result, value) => {
    const { instanceIndex } = value.match(ANSWER_VALUE_MATCH)?.groups || {}
    return Object.assign(result, { [instanceIndex]: (result[instanceIndex] || []).concat(value) })
  }, {} as Record<string, string[]>)

  const resultingValues = Object.values(instancedValues).reduce((result, instanceValueArray) => {
    let groupCount = optionGroupsWithLimits.reduce((result, { id }) => Object.assign(result, { [id]: 0 }), {} as Record<string, number>)
    const cappedValues = instanceValueArray.reduce((result, answerValue) => {
      const optionId = answerValue.match(ANSWER_VALUE_MATCH)?.groups?.source as string
      const relevantGroupCount = optionGroupsWithLimits.find(group => group.optionIds.includes(optionId))

      if (relevantGroupCount && (relevantGroupCount.count ?? 0) > groupCount[relevantGroupCount.id]) {
        result.push(answerValue)
        groupCount[relevantGroupCount.id]++
      }
      return result
    }, [] as string[])
    //@ts-ignore
    return result.concat(cappedValues.toReversed())
  }, [] as string[])

  const valueLengthDiffers = answer!.values.length !== resultingValues.length
  if (!valueLengthDiffers) return answer
  return { id: answer.id, values: resultingValues }
}
// ===================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ======================================================= GET VALUES FROM ANSWER ======================================================= //
// ====================================================================================================================================== //
export const getOptionPropertiesFromAnswer = (answer: Answer): Record<string, OptionValueProperties> =>
  answer.values.reduce((result, valueString) => {
    const { id, value } = valueString.match(ANSWER_VALUE_MATCH)?.groups || {}
    const properties = extractPropertiesFromCustomText(value, 'optionValue')
    return Object.assign(result, { [id]: properties })
  }, {})
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ================================================= GET OPTION VALUE FROM ANSWER BY ID ================================================= //
// ====================================================================================================================================== //
export const getOptionPropertiesFromAnswerById = (answer: Answer, id: string): OptionValueProperties => getOptionPropertiesFromAnswer(answer)[id]
// ====================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================== GET PARENT QUESTION ======================================================== //
// ===================================================================================================================================== //
// =================================================== GET PARENT QUESTION: OVERLOAD =================================================== //
// function getParentQuestion(state: WizardState, question: Question): Question | undefined
// function getParentQuestion(state: WizardState, questionId: string): Question | undefined
// ===================================================================================================================================== //
// function getParentQuestion(state: WizardState, questionParam: Question | string): Question | undefined {
//   const question = typeof questionParam === 'string' ? state.questions?.find(({ id }) => id === questionParam) : questionParam
//   const parentQuestionId = question?.advanced.subQuestionTo.match(SUB_QUESTION_TO_MATCH)?.groups?.questionId
//   return parentQuestionId ? state.questions?.find(({ id: questionId }) => questionId === parentQuestionId) : undefined
// }
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================== INSERT SUB QUESTION ======================================================== //
// ===================================================================================================================================== //
// const insertSubQuestion = (
//   questionOrder: QuestionLayout,
//   subQuestionId: string,
//   questions: Questions,
//   answerRelevance: AnswerRelevance
// ): QuestionLayout => {
//   const question = questions.find(({ id }) => id === subQuestionId)
//   const isRelevant = answerRelevance[subQuestionId]
//   if (!(question && isRelevant)) return questionOrder
//   const parentQuestionId = question.advanced.subQuestionTo.match(SUB_QUESTION_TO_MATCH)?.groups?.questionId
//   let inserted = false
//   return mergeQuestionLayoutGroups({
//     questionLayout: questionOrder
//       .reduce((result, group) => {
//         const resultingGroup = Object.assign({}, group, { questions: group.questions.slice() })
//         result.push(resultingGroup)
//         const [subbedQuestionId, index] = findWithIndex(resultingGroup.questions, id => {
//           const currentQuestionId = id.slice(Array.from(id.matchAll(new RegExp('(?<questionId>(?<=(sub-)|^)(sub-))', 'g'))).length * 4)
//           return currentQuestionId === parentQuestionId
//         })
//         if (index === -1) return result
//         const newDepth = Array.from(subbedQuestionId!.matchAll(new RegExp('(?<questionId>(?<=(sub-)|^)(sub-))', 'g'))).length + 1
//         const trailingSubQuestionsOffset = findWithIndex(
//           resultingGroup.questions.slice(index + 1),
//           id => id.slice(0, newDepth * 4) !== new Array(newDepth).fill('sub-').join('')
//         )[1]
//         if (trailingSubQuestionsOffset === -1) resultingGroup.questions.push(`${new Array(newDepth).fill('sub-').join('')}${subQuestionId}`)
//         else
//           resultingGroup.questions.splice(index + 1 + trailingSubQuestionsOffset, 0, `${new Array(newDepth).fill('sub-').join('')}${subQuestionId}`)
//         return (inserted = true) && result
//       }, [] as QuestionLayout)
//       .concat(
//         !inserted ? generateLooseQuestionLayoutGroup({ id: '__casus_unknown-parent-sub-question-insertion-group', questions: [subQuestionId] }) : []
//       ),
//   }).questionLayout
// }
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ====================================================== MAP IDS TO GROUP NUMBER ====================================================== //
// ===================================================================================================================================== //
const mapIdsToRegularGroupNumber = (groupId: string, instanceIndex: number, questions: string[], counter: number): string[] =>
  questions.map((questionId, i) => `${groupId}:${instanceIndex}:${generateRegularGroupIdOrderNumber(questionId, counter, i)}`)
const mapIdsToLooseGroupNumber = (groupId: string, instanceIndex: number, questions: string[], counter: number): string[] =>
  questions.map((questionId, i) => `${groupId}:${instanceIndex}:${generateLooseGroupIdOrderNumber(questionId, counter + i)}`)
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ====================================================== GENERATE QUESTION ORDER ====================================================== //
// ===================================================================================================================================== //
export const generateQuestionOrder = (
  questionLayout: QuestionLayout,
  instancing: Record<string, number> | null,
  questions: Questions,
  answerRelevance: AnswerRelevance,
  showExternal: boolean = true,
  showInternal: boolean = true
): string[] => {
  const baseLayout = questionLayout.filter(({ type }) => type !== 'sub-questions')
  const [questionOrder] = baseLayout.reduce(
    ([result, counter], group) => {
      const { id, type, questions: questionIds } = group
      if (type === 'sub-questions') return [result, counter] // IGNORE DEPRICATED "SUB-QUESTION" GROUP - remove this line after removing "sub-questions"
      const filteredQuestionIds = questionIds.filter(questionId => {
        if (showExternal && questions.find(question => question.id === questionId)?.isPrivate) return true
        if (showInternal && !questions.find(question => question.id === questionId)?.isPrivate) return true
        return false
      })
      const { instantiable, instanceLimit = Infinity } = group as QuestionLayoutRegularGroup
      const groupInstances = instancing?.[id] ?? 1
      if (!filteredQuestionIds.length)
        return [result.concat(instantiable && groupInstances < instanceLimit ? `${id}:${groupInstances - 1}:new-instance-prompt:??` : []), counter]
      if (type === 'loose') {
        const relevantQuestionIds = (filteredQuestionIds as string[]).filter(questionId => answerRelevance[`${questionId}:0`])
        return [result.concat(mapIdsToLooseGroupNumber(id, 0, relevantQuestionIds, counter)), counter + filteredQuestionIds.length]
      }
      const [groupOrder, newCounter] = new Array(groupInstances).fill(filteredQuestionIds).reduce(
        ([result, counter], questionIds, instanceIndex) => {
          const relevantQuestionIds = (questionIds as string[]).filter(questionId => answerRelevance[`${questionId}:${instanceIndex}`])
          return [result.concat(mapIdsToRegularGroupNumber(id, instanceIndex, relevantQuestionIds, counter)), counter + 1]
        },
        [result, counter]
      )
      return [
        groupOrder.concat(instantiable && groupInstances < instanceLimit ? `${id}:${groupInstances - 1}:new-instance-prompt:??` : []),
        newCounter,
      ]
    },
    [[] as string[], 1]
  ) as [string[], number]
  if (questionOrder.length) {
    questionOrder[0] = `:${questionOrder[0]}`
    questionOrder[questionOrder.length - 1] = `${questionOrder[questionOrder.length - 1]}:`
  }
  return questionOrder
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================= UPDATE QUESTION ORDER ======================================================= //
// ===================================================================================================================================== //
export const updateQuestionOrder = (state: WizardState) => {
  const { questionLayout, instancing, questions, answerRelevance, answering } = state
  if (!(questions && questionLayout && answerRelevance && isModeDocumentFlow(state.mode))) return state
  const questionOrder = generateQuestionOrder(
    questionLayout,
    instancing,
    questions,
    answerRelevance,
    Boolean(state.showExternalQuestions),
    Boolean(state.showInternalQuestions)
  )
  if (questionOrder.join('') === state.questionOrder?.join('')) return state
  const payload = { questionOrder }

  const answeringFound =
    answering &&
    questionOrder.some(
      orderString =>
        orderString.match(new RegExp('^(?::?)(?<id>.+?)(?::?)$'))?.groups?.id === answering?.match(new RegExp('^(?::?)(?<id>.+?)(?::?)$'))?.groups?.id
    )
  if (!answeringFound && questionOrder.length) {
    const { questionLayoutGroupId, instanceIndex, questionId } = answering?.match(ANSWERING_IDS_MATCH)?.groups ?? {}
    const newAnswering =
      (questionId === 'new-instance-prompt' &&
        questionOrder.find(orderString => {
          const { questionLayoutGroupId: groupId, instanceIndex: instance } = orderString.match(ANSWERING_IDS_MATCH)?.groups ?? {}
          return groupId === questionLayoutGroupId && Number(instance) === Number(instanceIndex) + 1
        })) ||
      questionOrder[0]
    Object.assign(payload, { answering: newAnswering })
  }
  return updateWizardState(state, payload)
}
// ===================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================= EVALUATE CONDITION ========================================================= //
// ====================================================================================================================================== //
export const evaluateConditionRule = (state: WizardState, conditionRule: QuestionConditionRule, instanceIndex?: number): boolean => {
  if (conditionRule.question) {
    const answer = getAnswerById(state, conditionRule.question)[0]
    switch (conditionRule.instance) {
      case 'all':
        return new Array(
          state.instancing?.[(getQuestionLayoutGroupByQuestionId(state, conditionRule.question)[0] as QuestionLayoutRegularGroup)?.id] ?? 1
        )
          .fill(conditionRule.option)
          .every(
            (optionId, instanceIndex) =>
              state.answerRelevance?.[`${conditionRule.question}:${instanceIndex}`] &&
              answer?.values.find(value => value.match(ANSWER_VALUE_MATCH)?.groups?.id === `${optionId}:${instanceIndex}`)
          )
      case 'current':
        return Boolean(
          state.answerRelevance?.[`${conditionRule.question}:${instanceIndex}`] &&
            answer?.values.find(value => value.match(ANSWER_VALUE_MATCH)?.groups?.id === `${conditionRule.option}:${instanceIndex}`)
        )
      case 'specific':
        return Boolean(
          state.answerRelevance?.[`${conditionRule.question}:${conditionRule.instanceIndex}`] &&
            answer?.values.find(value => value.match(ANSWER_VALUE_MATCH)?.groups?.id === `${conditionRule.option}:${conditionRule.instanceIndex}`)
        )
      case 'any':
      default:
        return Boolean(
          Object.keys(state.answerRelevance || {}).find(key => key.split(':').shift() === conditionRule.question && state.answerRelevance?.[key]) &&
            answer?.values.find(value => value.match(ANSWER_VALUE_MATCH)?.groups?.source === conditionRule.option)
        )
    }
  }
  return false
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ====================================================== EVALUATE CONDITION GROUP ====================================================== //
// ====================================================================================================================================== //
export const evaluateConditionGroup = (state: WizardState, conditionGroup: QuestionConditionGroup, instanceIndex?: number): boolean => {
  const conditions = Object.assign({}, conditionGroup) as Record<string, QuestionConditionRule>
  delete conditions.logic
  return Object.values(conditions).reduce((result, conditionRule) => {
    if (result === (conditionGroup.logic === LOGIC.OR)) return result
    return evaluateConditionRule(state, conditionRule, instanceIndex)
  }, (conditionGroup.logic ?? LOGIC.AND) === LOGIC.AND)
}
// ====================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================== EVALUATE CONDITIONS ======================================================== //
// ===================================================================================================================================== //
export const evaluateConditions = (
  state: WizardState,
  conditions: Record<string, QuestionConditionGroup> & { logic?: ValuesOf<typeof LOGIC> },
  instanceIndex?: number
): boolean => {
  const conditionGroups = Object.assign({}, conditions) as Record<string, QuestionConditionGroup>
  delete conditionGroups.logic
  if (!Object.keys(conditionGroups).length) return true
  return Object.values(conditionGroups).reduce((result, conditionGroup) => {
    if (result === (conditions.logic === LOGIC.OR)) return result
    return evaluateConditionGroup(state, conditionGroup, instanceIndex)
  }, (conditions.logic ?? LOGIC.AND) === LOGIC.AND)
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ===================================================== GET DOWNSTREAM DEPENDENTS ===================================================== //
// ===================================================================================================================================== //
export const getDownstreamDependents = (state: WizardState, ids: string[], type?: string, covered: string[] = []): string[] => {
  if (!ids.length) return []
  const dependents = Object.values(state.dependencies || {})
    .reduce((result, { dependent, dependency }) => {
      if (!ids.includes(dependency.id) || (type && dependent.type !== type)) return result
      covered.push(dependency.id)
      return result.concat(ids.includes(dependency.id) ? dependent.id : [])
    }, [] as string[])
    .filter(id => !(covered.includes(id) || ids.includes(id)))
  return dependents.concat(dependents.length ? getDownstreamDependents(state, dependents, type, covered) : [])
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ================================================ GET QUESTION CONDITIONAL DEPENDENTS ================================================ //
// ===================================================================================================================================== //
export const getQuestionConditionalDependents = (
  state: WizardState,
  ids: Record<string, Set<number>>,
  covered: Record<string, Set<number>> = {}
): Record<string, Set<number>> => {
  const relevantQuestions = ids ? Object.keys(ids) : []
  if (!relevantQuestions.length) return {}
  return Object.values(state.dependencies || {}).reduce((result, { dependent, dependency }) => {
    const question = state.questions?.find(({ id }) => id === dependent.id)
    const isDependencyRelevant = relevantQuestions.includes(dependency.id) && dependent.type === 'question' && question
    if (!isDependencyRelevant) return result

    const conditionGroups = Object.assign({}, question!.conditions)
    delete conditionGroups.logic
    const relevantRule = Object.values(conditionGroups)
      .reduce((result, group) => {
        const conditions = Object.assign({}, group) as QuestionConditionGroup
        delete conditions.logic
        return result.concat(Object.values(conditions) as QuestionConditionRule[])
      }, [] as QuestionConditionRule[])
      .find(({ dependencyId }) => dependencyId === dependency.id)

    const resultingDependentInstances = [] as number[]
    if (
      ids[dependency.id].has(-1) ||
      ['any', 'all'].includes(relevantRule?.instance!) ||
      (relevantRule?.instance === 'specific' && ids[dependency.id].has(relevantRule?.instanceIndex!))
    )
      resultingDependentInstances.push(-1)
    else if (relevantRule?.instance === 'current') resultingDependentInstances.push(...ids[dependency.id])

    const filteredDependentInstances = new Set(resultingDependentInstances.filter(instanceIndex => !result[dependent.id]?.has(instanceIndex)))
    if (filteredDependentInstances.size) {
      const relevantDependents = { [dependent.id]: filteredDependentInstances }
      return Object.assign(
        result,
        //@ts-ignore
        { [dependent.id]: filteredDependentInstances.union(result[dependent.id] || new Set()) },
        getQuestionConditionalDependents(state, relevantDependents, result) ?? {}
      )
    }
    return result
  }, covered as Record<string, Set<number>>)
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ===================================================== GENERATE ANSWER RELEVANCE ===================================================== //
// ===================================================================================================================================== //
export const generateAnswerRelevance = (state: WizardState, ids?: Record<string, Set<number>>): AnswerRelevance => {
  const relevantIds = Object.assign({}, ids, ids && getQuestionConditionalDependents(state, ids))
  const relevantQuestionIds = relevantIds ? Object.keys(relevantIds) : []

  const questions = (
    (relevantQuestionIds.length ? state.questions?.filter(({ id }) => relevantQuestionIds.includes(id)) : state.questions) || []
  ).sort(({ id: aId }, { id: bId }) => -selectWizardDependentsById({ wizard: state }, aId).split(';').indexOf(bId))

  return questions.reduce((result, { id, conditions }) => {
    const questionGroupInstances = state.instancing?.[(getQuestionLayoutGroupByQuestionId(state, id)[0] as QuestionLayoutRegularGroup)?.id] ?? 1
    return new Array(questionGroupInstances).fill(id).reduce((result, questionId, index) => {
      if (relevantQuestionIds?.length && !(relevantIds[id]!.has(-1) || relevantIds[id]!.has(index))) return result
      const updatedAnswerRelevance = Object.assign({}, state.answerRelevance, result)
      const pseudoRelevanceUpdatedState = Object.assign({}, state, { answerRelevance: updatedAnswerRelevance })
      return Object.assign(result, { [`${questionId}:${index}`]: evaluateConditions(pseudoRelevanceUpdatedState, conditions, index) })
    }, result)
  }, {} as AnswerRelevance)
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ==================================================== INITIALIZE ANSWER RELEVANCE ==================================================== //
// ===================================================================================================================================== //
export const initializeAnswerRelevance = (state: WizardState): WizardState =>
  updateWizardState(state, { answerRelevance: generateAnswerRelevance(state) })
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ====================================================== UPDATE ANSWER RELEVANCE ====================================================== //
// ===================================================================================================================================== //
export const updateAnswerRelevance = (state: WizardState, ids: Record<string, Set<number>>): WizardState => {
  const resultingAnswerRelevance = state.answerRelevance ? generateAnswerRelevance(state, ids) : generateAnswerRelevance(state)
  const updatedRelevance = Object.keys(resultingAnswerRelevance)
  const relevantAnswerIds = updatedRelevance.map(id => id.split(':')[0])

  const allMarkers = Object.values(Object.assign({}, state.locations?.segments, state.locations?.text)).flat() as (SegmentsLocation | TextLocation)[]
  const relevantMarkerIds = allMarkers.reduce((result, { id, valueSources, optionIds }) => {
    if (valueSources?.find(source => relevantAnswerIds.includes(source.split(':')[1]))) return result.concat(id)
    const relevantQuestionsForMarker = Array.from(
      (optionIds ?? []).reduce((result, optionId) => {
        const relevantQuestionId = state.questions?.find(({ optionGroups }) =>
          optionGroups
            .map(({ options }) => options)
            .flat()
            .find(({ id }) => optionId.split(':')[0] === id)
        )?.id
        return relevantQuestionId ? result.add(relevantQuestionId) : result
      }, new Set() as Set<string>)
    )
    if (relevantQuestionsForMarker.some(questionId => relevantAnswerIds.includes(questionId))) return result.concat(id)
    return result
  }, [] as string[])

  return relevantMarkerIds.reduce(
    (previousState, id) => evaluateMarker(previousState, id),
    updateWizardState(state, { answerRelevance: Object.assign({}, state.answerRelevance, resultingAnswerRelevance) })
  )
}
// ===================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================= GET COMPUTED VALUE ========================================================= //
// ====================================================================================================================================== //
const getComputedValue = (id: keyof typeof COMPUTED_VALUES): ValueSourceProperties & { id: string } =>
  Object.assign(extractPropertiesFromCustomText(COMPUTED_VALUES[id]?.value().match(SOURCE_VALUE_MATCH)?.groups?.value!, 'valueSource'), { id })
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================= GET EXTERNAL VALUE ========================================================= //
// ====================================================================================================================================== //
const getExternalValue = (state: WizardState, ids: string): (ValueSourceProperties & { id: string }) | null | undefined => {
  const [integrationId, fieldId] = ids.split(':')
  const relevantField = state.integrations && state.integrations[integrationId]?.fields?.find(({ id }) => id === fieldId)
  return relevantField && { id: ids, type: relevantField?.type, value: relevantField.value }
}
// ====================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ========================================================= GET QUESTION VALUE ========================================================= //
// ====================================================================================================================================== //
const getQuestionValue = (state: WizardState, id: string): (ValueSourceProperties & { id: string })[] | undefined => {
  const answer = getAnswerById(state, id)[0]
  return (
    answer &&
    Object.entries(getOptionPropertiesFromAnswer(answer)).map(([optionId, properties]) => Object.assign(properties, { id: `${id}:${optionId}` }))
  )
}
// ====================================================================================================================================== //
//
//
//
const valueSourceInstanceMatch = (
  valueSourceInstance1Values: (ValueSourceProperties & { id: string })[],
  valueSourceInstance2Values: (ValueSourceProperties & { id: string })[]
) =>
  valueSourceInstance1Values.length === valueSourceInstance2Values.length &&
  valueSourceInstance1Values.every(valueSourceInstance1Value =>
    valueSourceInstance2Values.find(({ id, value }) => id === valueSourceInstance1Value.id && value === valueSourceInstance1Value.value)
  )
const valueSourceMatch = (
  valueSource1Instances: Record<string, (ValueSourceProperties & { id: string })[]>,
  valueSource2Instances: Record<string, (ValueSourceProperties & { id: string })[]>
) => {
  const source1Values = Object.values(valueSource1Instances)
  const source2Values = Object.values(valueSource2Instances)
  return (
    source1Values.length === source2Values.length &&
    source1Values.every((instanceValues, i) => valueSourceInstanceMatch(instanceValues, source2Values[i]))
  )
}
const replaceMatch = (replace1: MarkerReplace, replace2: MarkerReplace) => {
  const replace1Entries = Object.entries(replace1)
  const replace2Keys = Object.keys(replace2)
  return (
    replace1Entries.length === replace2Keys.length &&
    replace1Entries.every(([valueSource]) => replace2Keys.includes(valueSource)) &&
    replace1Entries.every(([valueSource, valueSourceInstances]) => valueSourceMatch(valueSourceInstances, replace2[valueSource]))
  )
}
// ===================================================================================================================================== //
// ======================================================= EVALUATE KNOWN MARKER ======================================================= //
// ===================================================================================================================================== //
const evaluateKnownMarker = <T extends SegmentsLocation | TextLocation>(
  state: WizardState,
  marker: T,
  markerArray: T[],
  index: number,
  evaluateNumbering?: boolean,
  update?: never
): WizardState => {
  // #region VALUE SOURCES
  const replaceValues = (marker.valueSources || []).reduce((resultingValues, source) => {
    const { type, id } = source.match(VALUE_SOURCE_TYPE_MATCH)?.groups || {}
    if (type === 'question') {
      const parentQuestionGroup = getQuestionLayoutGroupByQuestionId(state, id)[0] as QuestionLayoutRegularGroup
      const questionGroupInstances = (parentQuestionGroup && state.instancing?.[parentQuestionGroup.id]) ?? 1
      const answerValue = getQuestionValue(state, id)
      const resultingAnswerValues = new Array(questionGroupInstances).fill(id).reduce((result, id, instanceIndex) => {
        const answerValues =
          state.answerRelevance && state.answerRelevance[`${id}:${instanceIndex}`]
            ? answerValue?.filter(answerValue => Number(answerValue.id.split(':')[2]) === instanceIndex)
            : []
        return Object.assign(result, { [`${instanceIndex}`]: answerValues ?? [] })
      }, {} as Record<string, string[]>)
      return Object.assign(resultingValues, { [source]: resultingAnswerValues })
    }
    if (type === 'external') {
      const externalValue = getExternalValue(state, id)
      if (externalValue?.value) return Object.assign(resultingValues, { [source]: { 0: [externalValue] } })
    }
    if (type === 'computed') {
      const computedValue = getComputedValue(id as keyof typeof COMPUTED_VALUES)
      return Object.assign(resultingValues, { [source]: { 0: [computedValue] } })
    }
    return resultingValues
  }, {} as MarkerReplace)
  const replaceUpdate = !replaceMatch(marker.replace || {}, replaceValues)
  // #endregion VALUE SOURCES
  //
  //
  //
  // #region CONDITIONALS
  const languageKeep = !marker.conditionals?.languages?.length
    ? true
    : marker.defaultKeep
    ? !state.languages?.selected?.length || marker.conditionals?.languages.some(language => state.languages!.selected!.includes(language))
    : marker.conditionals?.languages.every(language => state.languages?.selected?.includes(language))
  const splitKeep = !marker.conditionals?.splits?.length
    ? true
    : !state.activeSplit || marker.conditionals?.splits.some(split => split === state.activeSplit)
  // #endregion CONDITIONALS

  const allRelevantValues =
    state.answers?.reduce(
      (result, { id, values }) =>
        result.concat(
          values || []
          // .filter(value => state.answerRelevance?.[`${id}:${value.match(ANSWER_VALUE_MATCH)?.groups?.instanceIndex ?? 0}`]) // INSTANCES TODO
        ),
      [] as string[]
    ) || []

  const keepValues = allRelevantValues.filter(value =>
    (marker.optionIds || []).some(optionId => optionId.split(':')[0] === value.match(ANSWER_VALUE_MATCH)?.groups?.source!)
  )

  const keep = languageKeep && splitKeep && (marker.defaultKeep ? !marker.optionIds?.length || keepValues : !marker.optionIds?.length || keepValues)

  const keepUpdate =
    typeof marker.keep === 'boolean' || typeof keep === 'boolean'
      ? marker.keep !== keep
      : marker.keep?.length !== keep.length || !keep.some(value => (marker.keep as string[])?.includes(value))

  const instanceCount = marker.instancing ? state.instancing?.[marker.instancing] : 1
  const instanceUpdate = instanceCount !== marker.instanceCount

  const resultingMarker = Object.assign({}, marker)
  if (replaceUpdate) Object.assign(resultingMarker, { replace: replaceValues })
  if (keepUpdate) Object.assign(resultingMarker, { keep: keep })
  if (instanceUpdate) Object.assign(resultingMarker, { instanceCount })
  if (update || replaceUpdate || keepUpdate || instanceUpdate) {
    markerArray.splice(index, 1, resultingMarker)
    if (evaluateNumbering) applyParagraphNumbering(state)
    return Object.assign({}, state)
  }
  return state
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ======================================================= EVALUATE MARKER BY ID ======================================================= //
// ===================================================================================================================================== //
const evaluateMarkerById = (state: WizardState, markerId: string, evaluateNumbering?: boolean, update?: never): WizardState => {
  const markerById = getMarkerById(state, markerId)
  return markerById[0] ? evaluateKnownMarker(state, markerById[0], markerById[2], markerById[3], evaluateNumbering, update) : state
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ========================================================== EVALUATE MARKER ========================================================== //
// ===================================================================================================================================== //
// ===================================================== EVALUATE MARKER: OVERLOAD ===================================================== //
function evaluateMarker(state: WizardState, marker: FoundMarkerType, evaluateNumbering?: boolean, update?: never): WizardState
function evaluateMarker(state: WizardState, markerId: string, evaluateNumbering?: boolean, update?: never): WizardState
// ===================================================================================================================================== //
function evaluateMarker(state: WizardState, arg2: FoundMarkerType | string, evaluateNumbering: boolean = true, update = false as never): WizardState {
  if (typeof arg2 === 'string') return evaluateMarkerById(state, arg2, evaluateNumbering)
  if (Array.isArray(arg2) && arg2[0]) return evaluateKnownMarker(state, arg2[0], arg2[2], arg2[3], evaluateNumbering, update)
  return state
}
// ===================================================================================================================================== //
//
//
//
// ===================================================================================================================================== //
// ================================================== GET MARKER IDS BY VALUE SOURCES ================================================== //
// ===================================================================================================================================== //
const getMarkerIdsByValueSources = (state: WizardState, valueSources: string[]) => {
  const instanceStrippedValueSources = valueSources.map(source => {
    const { type, id } = source.match(VALUE_SOURCE_TYPE_MATCH)?.groups || {}
    return `${type}:${id}`
  })
  return Object.values(Object.assign({}, state.locations?.segments, state.locations?.text))
    .flat(1)
    .reduce(
      (result, marker) =>
        marker.valueSources?.find(source => {
          const { type, id } = source.match(VALUE_SOURCE_TYPE_MATCH)?.groups || {}
          return instanceStrippedValueSources.includes(`${type}:${id}`)
        })
          ? result.concat(marker.id)
          : result,
      [] as string[]
    )
}
// ===================================================================================================================================== //
//
//
//
// ====================================================================================================================================== //
// ==================================================== GET MARKER IDS BY INSTANCING ==================================================== //
// ====================================================================================================================================== //
const getMarkerIdsByInstancing = (state: WizardState, instancing: string) =>
  Object.values(Object.assign({}, state.locations?.segments, state.locations?.text))
    .flat(1)
    .reduce((result, marker) => result.concat(marker.instancing === instancing ? marker.id : []), [] as string[])
// ===================================================================================================================================== //
//
//
//
//
//
//
//
//
//
//
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ========================================================= REDUCER FUNCTIONS ========================================================= //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //
// ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// //

export type ToggleDocumentLanguagePayload = LanguageValue
const toggleDocumentLanguage = (state: WizardState, payload: ToggleDocumentLanguagePayload) => {
  const select = state.languages?.select || 'single'
  const languageSlice = state.languages?.selected?.slice() || []
  const index = languageSlice.indexOf(payload)
  if (index === -1) languageSlice.push(payload)
  else languageSlice.splice(index, 1)
  const resultingSelectedLanguages = select === 'multi' ? languageSlice : (index !== -1 && []) || [payload]
  const resultingLanguages = Object.assign({}, state.languages, { selected: resultingSelectedLanguages })

  const languageDifference = select === 'single' ? (state.languages?.selected || []).concat(payload) : [payload]
  const relevantMarkerIds = Object.values(Object.assign({}, state.locations?.segments, state.locations?.text))
    .flat(1)
    .reduce(
      (result, { id, conditionals }) =>
        languageDifference.some(language => conditionals?.languages?.includes(language)) ? result.concat(id) : result,
      [] as string[]
    )
  relevantMarkerIds.reduce((previousState, id) => evaluateMarker(previousState, id), state)
  return updateWizardState(state, { languages: resultingLanguages })
}

export type ChooseIntegrationEntryPayload = { integrationId: string; entryId: string }
const chooseIntegrationEntry = (state: WizardState, payload: ChooseIntegrationEntryPayload) => {
  if (!(payload.integrationId && payload.entryId)) return state
  const resultingIntegrationEntries = Object.assign({}, state.integrationEntries, { [payload.integrationId]: payload.entryId })
  return updateWizardState(state, { integrationEntries: resultingIntegrationEntries })
}

export type UpdateIntegrationFieldValuesPayload = Record<string, Record<string, string>>
const updateIntegrationFieldValues = (state: WizardState, payload: UpdateIntegrationFieldValuesPayload) => {
  const resultingIntegrations = Object.entries(payload).reduce((result, [integrationId, fields]) => {
    const integration = result[integrationId] || {}
    const resultingFields = integration.fields?.slice().map(field => Object.assign({}, field, { value: fields[field.id] }))
    const resultingIntegration = Object.assign({}, integration, { fields: resultingFields })
    return Object.assign(result, { [integrationId]: resultingIntegration })
  }, Object.assign({}, state.integrations))
  const changedFields = Object.entries(payload).reduce(
    (result, [integrationId, fields]) =>
      result.concat(
        Object.entries(fields).reduce(
          (result, [fieldId, fieldValue]) =>
            state.integrations && state.integrations[integrationId]?.fields?.find(({ id }) => id === fieldId)?.value !== fieldValue
              ? result.concat(`integration:${integrationId}:${fieldId}`)
              : result,
          [] as string[]
        )
      ),
    [] as string[]
  )
  const relevantMarkerIds = getMarkerIdsByValueSources(state, changedFields)
  relevantMarkerIds.reduce((previousState, id) => evaluateMarker(previousState, id), state)
  return updateWizardState(state, { integrations: resultingIntegrations })
}

export type ResetIntegrationFieldValuesPayload = string[] | undefined
const resetIntegrationFieldValues = (state: WizardState, payload: ResetIntegrationFieldValuesPayload) => {
  const resultingIntegrations = Object.entries(state.integrations || {}).reduce((result, [integrationId, integration]) => {
    if (!payload || payload.includes(integrationId)) {
      const resultingFields = integration.fields?.map(field => {
        const res = Object.assign({}, field)
        delete res.value
        return res
      })
      const resultingIntegration = Object.assign({}, integration, { fields: resultingFields })
      return Object.assign(result, { [integrationId]: resultingIntegration })
    }
    return result
  }, {})
  const changedFields = Object.entries(state.integrations || {}).reduce(
    (result, [integrationId, { fields }]) => result.concat(fields.map(({ id: fieldId }) => `integration:${integrationId}:${fieldId}`)),
    [] as string[]
  )
  const relevantMarkerIds = getMarkerIdsByValueSources(state, changedFields)
  relevantMarkerIds.reduce((previousState, id) => evaluateMarker(previousState, id), state)
  return updateWizardState(state, { integrations: resultingIntegrations })
}

const toggleShowExternalQuestions = (state: WizardState): WizardState =>
  updateWizardState(state, { showExternalQuestions: !state.showExternalQuestions }) as WizardState

const navigateQuestionnaireForward = (state: WizardState): WizardState => {
  const { questionOrder, answering } = state
  if (!questionOrder?.length) return state
  if (!answering) return updateWizardState(state, { answering: questionOrder[0] })
  const fullOrder = ['redirect-backward'].concat(questionOrder).concat('redirect-forward')
  // const fullOrder = questionOrder.concat(Object.values(QUESTIONNAIRE_POST_STEPS))
  // fullOrder.unshift(...Object.values(QUESTIONNAIRE_PRE_STEPS).slice().reverse())

  const index = fullOrder.findIndex(
    orderString =>
      orderString.match(new RegExp('^(?::?)(?<id>.+?)(?::?)$'))?.groups?.id === answering.match(new RegExp('^(?::?)(?<id>.+?)(?::?)$'))?.groups?.id
  )
  const resultingAnswering = fullOrder[index + 1]
  return Object.values(QUESTIONNAIRE_NAVIGATION_CONDITIONAL_METHODS).reduce(
    (result, { conditional, method }) =>
      conditional(result, QUESTIONNAIRE_NAVIGATION_DIRECTIONS.FORWARDS, answering)
        ? method(result, QUESTIONNAIRE_NAVIGATION_DIRECTIONS.FORWARDS)
        : result,
    updateWizardState(state, { answering: resultingAnswering || 'redirect-forward' }) as WizardState
  )
}

const navigateQuestionnaireBackward = (state: WizardState): WizardState => {
  const { questionOrder, answering } = state
  if (!questionOrder?.length) return state
  if (!answering) return updateWizardState(state, { answering: questionOrder[0] })
  const fullOrder = ['redirect-backward'].concat(questionOrder).concat('redirect-forward')
  // const fullOrder = questionOrder.concat(Object.values(QUESTIONNAIRE_POST_STEPS))
  // fullOrder.unshift(...Object.values(QUESTIONNAIRE_PRE_STEPS).slice().reverse())
  const index = fullOrder.findIndex(
    orderString =>
      orderString.match(new RegExp('^(?::?)(?<id>.+?)(?::?)$'))?.groups?.id === answering.match(new RegExp('^(?::?)(?<id>.+?)(?::?)$'))?.groups?.id
  )
  const resultingAnswering =
    fullOrder[index - 1].match(ANSWERING_IDS_MATCH)?.groups?.questionId === 'new-instance-prompt' ? fullOrder[index - 2] : fullOrder[index - 1]
  return Object.values(QUESTIONNAIRE_NAVIGATION_CONDITIONAL_METHODS).reduce(
    (result, { conditional, method }) =>
      conditional(result, QUESTIONNAIRE_NAVIGATION_DIRECTIONS.BACKWARDS, answering)
        ? method(result, QUESTIONNAIRE_NAVIGATION_DIRECTIONS.BACKWARDS)
        : result,
    updateWizardState(state, { answering: resultingAnswering || 'redirect-backward' }) as WizardState
  )
}

export type NavigateQuestionnaireToPayload = string
const navigateQuestionnaireTo = (state: WizardState, payload: NavigateQuestionnaireToPayload): WizardState => {
  console.log('QUESTION ORDER: ', state.questionOrder)
  debugger
  const {
    // questionLayoutGroupId,
    instanceIndex,
    questionId,
    // orderString,
  } = payload.match(ANSWERING_IDS_MATCH)?.groups || {}
  if (!questionId || !(instanceIndex || Number(instanceIndex) === 0) || state.answering === payload) return state
  // if (questionLayoutGroupId && instanceIndex && orderString) return updateWizardState(state, { answering: payload })
  // const answeringString = state.questionOrder?.find(orderString => orderString.split(':')[1] === questionId)
  const answeringString = state.questionOrder?.find(orderString => {
    const match = orderString.match(ANSWERING_IDS_MATCH)?.groups || {}
    return match.instanceIndex === instanceIndex && match.questionId === questionId
  })
  return updateWizardState(state, { answering: answeringString })
}

export type AddQuestionGroupInstancePayload = string
const addQuestionGroupInstance = (state: WizardState, payload: AddQuestionGroupInstancePayload) => {
  const groupIndex = (state.questionLayout ?? []).findIndex(({ id }) => id === payload)
  if (groupIndex === -1) return state
  const resultingInstances = (state.instancing?.[payload] ?? 1) + 1
  const resultingInstancing = Object.assign({}, state.instancing, { [payload]: resultingInstances })

  // UPDATE THE GROUP - OPTIONAL - IF NEEDED FOR THE COMPONENTS THAT HAVE ACCESS TO THE GROUP AND NOT TO THE GENERAL INSTANCING
  // const group = state.questionLayout![groupIndex]
  // const resultingLayoutGroup = Object.assign({}, group, { instances: resultingInstances })
  // state.questionLayout?.splice(groupIndex, 1, resultingLayoutGroup)

  const relevantMarkerIds = getMarkerIdsByInstancing(state, payload)
  const resultingAnswerRelevance = generateAnswerRelevance(fullAssign({}, state, { instancing: resultingInstancing }) as WizardState)
  return relevantMarkerIds.reduce(
    (previousState, id) => evaluateMarker(previousState, id),
    updateWizardState(state, { answerRelevance: resultingAnswerRelevance })
  )
}

export type AnswerWithOptionPayload = { questionId: string; value: string }
const answerWithOption = (state: WizardState, payload: AnswerWithOptionPayload): WizardState => {
  const { questionId, value } = payload
  const [answer, index] = getAnswerById(state, questionId)
  const { id, source: optionId, instanceIndex } = value?.match(ANSWER_VALUE_MATCH)?.groups || {}

  if (!optionId) return state

  const resultingAnswerValues = answer?.values.slice() ?? []
  const [existingAnswerValue, existingIndex] = getAnswerValue(
    { values: resultingAnswerValues } as Answer,
    value => value.match(ANSWER_VALUE_MATCH)?.groups?.id === id
  )

  if (!existingAnswerValue) resultingAnswerValues.push(value)
  else resultingAnswerValues.splice(existingIndex, 1, value)

  const resultingAnswer = tidyAnswer(state, generateAnswer(Object.assign({ id: questionId, values: resultingAnswerValues })))

  if (index === -1) Object.assign(state, { answers: (state.answers || []).concat(resultingAnswer) })
  else state.answers!.splice(index, 1, resultingAnswer)

  // ============================================================================================ //
  // ===================================== UPDATE RELEVANCE ===================================== //
  // ============================================================================================ //
  const dependentQuestions = Object.entries(state.dependencies || {}).reduce((result, [dependencyId, { dependent, dependency }]) => {
    if (!(dependency.type === 'question' && dependency.id === questionId)) return result
    return dependent.type === 'question' ? Object.assign(result, { [dependent.id]: (result[dependent.id] || []).concat(dependencyId) }) : result
  }, {} as Record<string, string[]>)

  const relevantQuestionInstances = Object.entries(dependentQuestions).reduce((result, [questionId, dependencyIds]) => {
    const question = getQuestionById(state, questionId)[0]
    if (!question) return result
    const groups = Object.assign(question.conditions)
    delete groups.logic
    const conditionRules = (Object.values(groups) as QuestionConditionGroup[])
      .reduce((result, conditionGroup) => {
        const group = Object.assign({}, conditionGroup)
        delete group.logic
        return result.concat(Object.values(group) as QuestionConditionRule[])
      }, [] as QuestionConditionRule[])
      .filter(({ dependencyId }) => dependencyIds.includes(dependencyId!))

    const resultingRelevantInstances = new Set(
      conditionRules.reduce((result, { instance = 'any', instanceIndex: index }) => {
        if (['any', 'all'].includes(instance!) || (instance === 'specific' && index === Number(instanceIndex))) return result.concat(-1)
        if (instance === 'current') return result.concat(Number(instanceIndex))
        return result
      }, [] as number[])
    )

    return resultingRelevantInstances.size
      ? Object.assign(result, { [questionId]: resultingRelevantInstances.has(-1) ? new Set([-1]) : resultingRelevantInstances })
      : result
  }, {} as Record<string, Set<number>>)

  updateAnswerRelevance(state, relevantQuestionInstances)
  // ============================================================================================ //

  // ============================================================================================ //
  // ====================================== UPDATE MARKERS ====================================== //
  // ============================================================================================ //
  const previouslySelectedOptionIds = new Set((answer?.values || []).map(value => value.match(ANSWER_VALUE_MATCH)?.groups?.id) as string[])
  const resultingSelectedOptionIds = new Set(resultingAnswer.values.map(value => value.match(ANSWER_VALUE_MATCH)?.groups?.id) as string[])

  const changedOptionIds = previouslySelectedOptionIds
    //@ts-ignore
    .union(resultingSelectedOptionIds)
    //@ts-ignore
    .difference(previouslySelectedOptionIds.intersection(resultingSelectedOptionIds))

  const relevantOptionIds = Array.from(changedOptionIds.add(id)).map(instancedOptionId => (instancedOptionId as string).split(':')[0])

  const [question] = getQuestionById(state, payload.questionId)
  const relevantReplacementMarkerIds = getMarkerIdsByValueSources(state, [`question:${payload.questionId}`])
  const relevantShowHideMarkerIds = new Set(
    question?.optionGroups.reduce(
      (result, { options }) =>
        result.concat(options.reduce((result, option) => result.concat(relevantOptionIds.includes(option.id) ? option.markers : []), [] as string[])),
      [] as string[]
    )
  )
  const relevantMarkerIds = relevantReplacementMarkerIds.concat(Array.from(relevantShowHideMarkerIds))
  const markerIgnoreList = [] as string[]

  relevantMarkerIds.reduce((previousState, id) => (!markerIgnoreList.includes(id) ? evaluateMarker(state, id) : previousState), state)
  // ============================================================================================ //

  return Object.assign({}, state)
}

export type UnanswerOptionPayload = { id: string; questionId: string }
const unanswerOption = (state: WizardState, payload: UnanswerOptionPayload): WizardState => {
  const { id, questionId } = payload
  const [answer, index] = getAnswerById(state, questionId)
  const instanceIndex = id.split(':')[0]

  if (index === -1) return state
  const resultingValues = answer!.values.filter(answerValue => answerValue.match(ANSWER_VALUE_MATCH)?.groups?.id !== id)
  if (answer!.values.length === resultingValues.length) return state
  state.answers!.splice(index, 1, { id: questionId, values: resultingValues })
  const [question] = getQuestionById(state, payload?.questionId)
  const option = (question
    ? extractFromNestedStructure(question, ['optionGroups', 'options'], option => option.id === id)
    : [])[0] as unknown as Option

  // ============================================================================================ //
  // ===================================== UPDATE RELEVANCE ===================================== //
  // ============================================================================================ //
  const dependentQuestions = Object.entries(state.dependencies || {}).reduce((result, [dependencyId, { dependent, dependency }]) => {
    if (!(dependency.type === 'question' && dependency.id === questionId)) return result
    return dependent.type === 'question' ? Object.assign(result, { [dependent.id]: (result[dependent.id] || []).concat(dependencyId) }) : result
  }, {} as Record<string, string[]>)

  const relevantQuestionInstances = Object.entries(dependentQuestions).reduce((result, [questionId, dependencyIds]) => {
    const question = getQuestionById(state, questionId)[0]
    if (!question) return result
    const groups = Object.assign(question.conditions)
    delete groups.logic
    const conditionRules = (Object.values(groups) as QuestionConditionGroup[])
      .reduce((result, conditionGroup) => {
        const group = Object.assign({}, conditionGroup)
        delete group.logic
        return result.concat(Object.values(group) as QuestionConditionRule[])
      }, [] as QuestionConditionRule[])
      .filter(({ dependencyId }) => dependencyIds.includes(dependencyId!))

    const resultingRelevantInstances = new Set(
      conditionRules.reduce((result, { instance, instanceIndex: index }) => {
        if (['any', 'all'].includes(instance!) || (instance === 'specific' && index === Number(instanceIndex))) return result.concat(-1)
        if (instance === 'current') return result.concat(Number(instanceIndex))
        return result
      }, [] as number[])
    )

    return resultingRelevantInstances.size
      ? Object.assign(result, { [questionId]: resultingRelevantInstances.has(-1) ? new Set([-1]) : resultingRelevantInstances })
      : result
  }, {} as Record<string, Set<number>>)

  updateAnswerRelevance(state, relevantQuestionInstances)
  // ============================================================================================ //

  // ============================================================================================ //
  // ====================================== UPDATE MARKERS ====================================== //
  // ============================================================================================ //
  const relevantReplacementMarkerIds = getMarkerIdsByValueSources(state, [`question:${payload.questionId}`])
  const relevantMarkerIds = relevantReplacementMarkerIds.concat(option?.markers || [])
  const markerIgnoreList = [] as string[]
  relevantMarkerIds.reduce((previousState, id) => (!markerIgnoreList.includes(id) ? evaluateMarker(state, id) : previousState), state)
  // ============================================================================================ //

  return Object.assign({}, state)
}

const evaluateMarkers = (state: WizardState, evaluateNumbering: boolean = true): WizardState => {
  if (!isModeDocumentFlow(state.mode)) return state
  const markers = Object.entries(Object.assign({}, state.locations?.segments, state.locations?.text)).reduce(
    (result, [parentId, markerArray]) => result.concat(markerArray.map((marker, index) => [marker, parentId, markerArray, index])),
    [] as FoundMarkerType[]
  )
  return markers.reduce((currentState, marker) => evaluateMarker(currentState, marker, evaluateNumbering), state)
}

export type ActivateSplitPayload = string
const activateSplit = (state: WizardState, payload: ActivateSplitPayload) => {
  if (state.activeSplit === payload) return state
  const relevantMarkerIds = Object.values(Object.assign({}, state.locations?.segments, state.locations?.text))
    .flat(1)
    .reduce((result, { id, conditionals }) => {
      const relevantToPrevious = conditionals?.splits?.includes(state.activeSplit as string)
      const relevantToNext = conditionals?.splits?.includes(payload)
      return (relevantToPrevious && relevantToNext) !== (relevantToPrevious || relevantToNext) ? result.concat(id) : result
    }, [] as string[])
  relevantMarkerIds.reduce((previousState, id) => evaluateMarker(previousState, id), state)
  return updateWizardState(state, { activeSplit: payload })
}

export {
  toggleDocumentLanguage,
  chooseIntegrationEntry,
  updateIntegrationFieldValues,
  resetIntegrationFieldValues,
  toggleShowExternalQuestions,
  navigateQuestionnaireForward,
  navigateQuestionnaireBackward,
  navigateQuestionnaireTo,
  addQuestionGroupInstance,
  answerWithOption,
  unanswerOption,
  evaluateMarkers,
  activateSplit,
}
